<template>
  <div class="flex flex-row items-center">
    <img
      v-if="!isMm && !noIcon"
      :src="icon || '/A_Logomark.svg'"
      alt="Icon"
      class="w-5 h-5 inline-block mr-1"
    />
    <span class="label-m" :class="isMm ? '-mb-1' : 'text-red'">
      {{ computedLabel }}
    </span>
    <span
      v-if="link"
      class="uppercase text-black text-opacity-50 ml-auto label-s transition-opacity duration-100 hover:opacity-100"
    >
      <NuxtLink :to="link">{{ $t(linkLabel) }}</NuxtLink>
    </span>
  </div>
</template>

<script setup lang="ts">
const { isMm } = useBrand()
const indexStore = useIndexStore()
const nuxtApp = useNuxtApp()

const props = withDefaults(
  defineProps<{
    icon?: string
    noIcon?: boolean
    label?: string
    linkLabel?: string
    link?: string
    translateLabel?: boolean
  }>(),
  { noIcon: false, label: '', linkLabel: 'ShowAll', translateLabel: true }
)

const computedLabel = computed(() => {
  if (
    props.label === 'MostRead' &&
    indexStore.currentPaper &&
    indexStore.currentPaper.RecordId !== 1
  ) {
    return `${labelOrTranslation.value} ${indexStore.currentPaper.Name}`
  } else {
    return labelOrTranslation.value
  }
})

const labelOrTranslation = computed(() => {
  if (props.translateLabel && nuxtApp.$te(props.label)) {
    return nuxtApp.$t(props.label)
  } else {
    return props.label
  }
})
</script>
